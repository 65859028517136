import { get, post, put, Delete } from "../axios";

// 新闻资讯-列表
export const newsInfoList = (params) => get("/web/news/info/list", params);

// 新闻资讯-新增
export const newsInfoAdd = (params) => post("/web/news/info/add", params);

// 新闻资讯-编辑
export const newsInfoUpdate = (params) => put("/web/news/info/update", params);

// 新闻资讯-删除
export const newsInfoDelete = (id) => Delete("/web/news/info/delete/" + id, "");

// 新闻资讯-详情
export const newsInfoview = (id) => get("/web/news/info/view/" + id, "");

// 新闻资讯-首页推荐-状态编辑
export const newsInfoHomeUpdate = (params) =>
  put("/web/news/info/home/update", params);
