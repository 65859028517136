<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{
        `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}新闻资讯`
      }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="intro">
        <el-input
          v-model="form.intro"
          placeholder="请输入"
          style="width: 400px"
          maxlength="200"
          :disabled="disabled"
          type="textarea"
          :rows="8"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="图片" :span="8" prop="image">
        <FormImgUpload
          :url="form.image"
          @upload="uploadImg"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
          :disabled="disabled"
        />
        <div style="color: #c0c1c3">
          建议尺寸：330px*186px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
        </div>
      </el-form-item>
      <!-- <el-form-item label="文件" prop="solutionUrl">
        <FormFileUpload
          :url="form.solutionUrl"
          @upload="upload"
          :disabled="disabled"
          :limit="1"
          :maxSize="500"
          :accept="'.pdf'"
          :multiple="true"
          :isFileName="'上传成功'"
          :showView="true"
        />
        <div style="color: #c0c1c3">文件格式：.pdf</div>
      </el-form-item> -->
      <!-- <el-form-item label="文本内容" prop="industry">
        <el-input
          v-model="form.industry"
          placeholder="请输入"
          style="width: 400px"
          maxlength="500"
          :disabled="disabled"
          type="textarea"
          :rows="8"
          show-word-limit
        ></el-input>
      </el-form-item> -->
      <el-form-item label="视频" prop="video">
        <div>
          <FormVideoUpload
            :url="form.video"
            @upload="uploadVideo"
            :limit="1"
            :maxSize="500"
            :accept="'.mp4,.webm,.avi'"
            :multiple="false"
          />
          <span style="color: #c0c1c3">
            最大500M，视频格式：.mp4,.webm,.avi
          </span>
        </div>
      </el-form-item>
      <el-form-item label="内容" :span="8" prop="content">
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="disabled"
          :isVideo="false"
        />
      </el-form-item>

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit" v-if="!disabled"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { newsInfoAdd, newsInfoUpdate, newsInfoview } from "@/api/news/info";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import FormVideoUpload from "@/components/formComponents/formVideoUpload";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
    FormImgUpload,
    FormVideoUpload,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        title: "",
        intro: "",
        image: [],
        content: "",
        video: [],
      },
      //校验规则
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        intro: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        // solutionUrl: [
        //   {
        //     required: true,
        //     message: "请上传",
        //     trigger: "blur",
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (value.length === 0) {
        //         callback(new Error("请上传"));
        //       } else {
        //         callback();
        //       }
        //     },
        //   },
        // ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
          {
            validator(_, value) {
              if (value) {
                if (value === "<p><br></p>") {
                  return Promise.reject(new Error("请输入内容"));
                }
                if (value.length > 65535) {
                  return Promise.reject(new Error("内容超出最大长度限制"));
                }
              }
              return Promise.resolve(true);
            },
          },
        ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await newsInfoview(this.id);
        this.form = {
          ...data,
          image: data.image.split(","),
          video: data.video ? data.video.split(",") : [],
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
        image: this.form.image.join(","),
        video: this.form.video.join(","),
      };

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          if (this.type == "add") {
            newsInfoAdd({
              ...newVal,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  // this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          } else {
            newsInfoUpdate({
              ...newVal,
              id: this.id,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  // this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          }
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //文件上传
    upload(e, name, size) {
      console.log(name, size, "name,sizename,sizename,size");

      if (Array.isArray(e)) {
        this.form.downloadUrl = [...e];
      } else {
        this.form.downloadUrl = [...this.form.downloadUrl, e];
      }
      this.form.urlName = name;
      this.form.urlSize = size ? size.toFixed(2) : "";
    },
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    //视频上传
    uploadVideo(e, name, size, duration, index) {
      if (Array.isArray(e)) {
        this.form.video = [...e];
      } else {
        this.form.video = [e];
      }
    },
    revert() {
      this.$router.go(-1);
    },
    // 切换类型
    getType(e) {
      if (e == 1) {
        this.form.content = "";
      } else {
        this.form.content = [];
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
